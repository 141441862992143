<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {getBrokerageApi} from '@/api/brokerage'


/**
 * Projects Grid component
 */
export default {
  page: {
    title: "Office",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Office ",
      items: [
        {
          text: "Brokerage",
        },
        {
          text: "Office",
          active: true,
        },
      ],
      offices : [],
    };
  },
  components: {
    Layout,
    PageHeader,
  },

  methods: {
    office_edit_route(office) {
      if (!office.managers) {
        office.managers = []
      }

      if (!office.conveyance) {
        office.conveyance = []
      }
      if (!office.admins) {
        office.admins = []
      }
      this.$router.push({name : 'brokerage-office-edit', params:{office : office}})
    }
  },

  created() {
    getBrokerageApi().officeApi.list().then((res)=>{
      res.data.map((o) =>{
        let managers     = []
        let conveyancers = []
        let admins       = []
        if (o.staffs) {
          o.staffs.map((s) => {
            if (s.staff_role == 'MANAGER') {
              managers.push(s)
            }  else  if (s.staff_role == 'CONVEYANCE') {
              conveyancers.push(s)
            }  else  if (s.staff_role == 'ADMIN') {
              admins.push(s)
            }
          })
        }
        this.offices.push({
          staffs       : o.staffs,
          name         : o.name,
          branch_name  : o.branch_name,
          address      :o.address,
          id           :o.id,
          descriptions : o.descriptions,
          team_pic     : o.team_pic,
          phone1       : o.phone1,
          phone2       : o.phone2,
          conveyancers : conveyancers,
          admins       : admins,
          managers     : managers,
          office_code  : o.office_code,
          bcfsa_code   : o.bcfsa_code
        })
      })  

    })
  },
  mounted() {
  

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="mb-3">
                  <router-link to="/brokerage/office/edit" class="btn btn-light">
                    <i class="uil uil-plus me-1"></i> Add New Office
                  </router-link>
                </div>
              </div>
              <!-- end col -->
              <div class="col-md-9">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3"
                >
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="mt-2">

                  <div class="row">
                    <div
                      class="col-xl-6 col-sm-6"
                      v-for="(item, index) in offices"
                      :key="index"
                    >
                      <div class="card border shadow-none border">
                        <div class="card-body">
                          <div class="d-flex align-items-start mb-3">
                            <div class="flex-grow-1">
                              <div
                                class="badge badge-soft-primary font-size-12"
                              >
                                {{ item.name }}
                              </div>
                            </div>
                            <b-dropdown
                              variant="white"
                              class="flex-shrink-0"
                              toggle-class="text-body font-size-16 p-0"
                              right
                            >
                              <template v-slot:button-content>
                                <feather
                                  type="more-horizontal"
                                  class="icon-xs"
                                ></feather>
                              </template>
                              <b-dropdown-item @click="office_edit_route(item)">Edit</b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div>
                            <h5 class="font-size-15 mb-1 text-truncate">
                              <router-link
                                to="/projects/overview"
                                class="text-dark"
                                >{{ item.branch_name }}</router-link
                              >
                            </h5>
                            <p class="text-muted mb-4 text-truncate">
                              {{ item.address }}
                            </p>
                            <p class="text-muted mb-4 text-truncate">
                              Office Code:{{ item.office_code }}
                            </p>
                          </div>

                          <div class="border-top pt-3">
                            <div class="avatar-group align-items-center">
                              <div class="me-4">Staff :</div>
                              <div class="avatar-group">
                                <div
                                  class="avatar-group-item"
                                  v-for="(staff, index) in item.staffs"
                                  :key="index"
                                >
                                  <a
                                    href="javascript: void(0);"
                                    class="d-block"
                                    v-if="staff.staff_avatar"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    v-b-tooltip.hover
                                    :title="staff.name"
                                  >
                                    <div class="avatar-sm">
                                      <img
                                        :src="`${staff.staff_avatar}`"
                                        alt=""
                                        class="img-fluid rounded-circle"
                                      />
                                    </div>
                                  </a>
                                  <a
                                    href="javascript: void(0);"
                                    v-if="!staff.staff_avatar"
                                    class="d-block"
                                    v-b-tooltip.hover
                                    :title="staff.staff_name"
                                  >
                                    <div class="avatar-sm">
                                      <div
                                        class="avatar-title rounded-circle bg-primary"
                                      >
                                        {{ staff.staff_name.charAt(0) }}
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end card body -->
                      </div>
                      <!-- end card -->
                    </div>
                  </div>
               
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
